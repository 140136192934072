import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { Card, Shape, ShapeWrapper, Text } from '@components';
import { CouponRedeem } from '@containers';
import { Box, Grid, Show } from '@core';
import { GA4_EVENTS } from '@shared/constants/google';
import { trackCustomGA4Event } from '@shared/google-utils';
import { ONE_MONTH_PLAN } from '@shared/constants/checkout';

import { CheckMarkBullet, CrossedSpan } from '../../components';

const PlansWrapper = styled.div`
  column-gap: 16px;
  row-gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ChoosePlan = ({
  isLoading,
  plans,
  selectedCard,
  t,
  plansParameters,
  couponError,
  getByCoupon,
  setSelectedCard,
  showRedNotice,
  showCheckList = true,
}) => (
  <React.Fragment>
    <ShapeWrapper
      shape={() => <Shape.Blob11 />}
      shapeProps={{
        height: { _: '124%', xl: '113%' },
        zIndex: 0,
        top: { _: '-3%', xl: '-10%' },
      }}
      wrapperProps={{ overflow: 'unset' }}
    >
      <Box maxWidth={1200} m="auto">
        {/*Plan picker*/}
        <Grid.Container my={16} minHeight={320} px={{ _: 16, xl: 0 }}>
          <PlansWrapper>
            {isLoading ? (
              <Card.CheckoutCard isLoading />
            ) : (
              plans.map((plan) => {
                const {
                  id,
                  coupon,
                  currency,
                  discountPercentage,
                  extraTime,
                  isHighlighted,
                  price,
                  pricePerMonth,
                  regularPrice,
                  subscriptionPeriod,
                  order,
                } = plan;

                const moneyBackGuaranteeText =
                  plan.subscriptionPeriod === ONE_MONTH_PLAN
                    ? t('common:14dayMoneyBackGuarantee')
                    : t('common:30dayMoneyBackGuarantee');

                return (
                  <Grid.Col
                    order={{ _: order?.mobile, xl: order?.desktop }}
                    width={{ _: '100%', xl: 'fit-content' }}
                    maxWidth={688}
                    key={id}
                  >
                    <Card.CheckoutCard
                      dataTestId={`plan-card-${subscriptionPeriod}`}
                      onClick={
                        setSelectedCard
                          ? () => {
                              setSelectedCard(plan);
                              trackCustomGA4Event({
                                eventName: GA4_EVENTS.planClick,
                                params: { plan: plan.identifier },
                              });
                            }
                          : null
                      }
                      isActive={selectedCard.id === id}
                      isHighlighted={isHighlighted}
                      button={
                        !setSelectedCard && {
                          title: t('common:actions.getPremium'),
                        }
                      }
                      subscriptionPeriod={plan.subscriptionPeriod}
                      title={
                        <Trans
                          i18nKey="plans:checkoutCard.title"
                          values={{
                            title: t(`plans:checkoutCard.periodTitle.${subscriptionPeriod}`),
                            extraComment: extraTime && t(`plans:monthsExtra.${extraTime}`),
                          }}
                          components={[
                            <Text.Body2
                              as="span"
                              $fontWeight="inherit"
                              $fontSize="inherit"
                              $lineHeight="inherit"
                              color="primary"
                              whiteSpace="nowrap"
                              key={0}
                            />,
                          ]}
                        />
                      }
                      billingDescription={
                        <Trans
                          i18nKey={
                            regularPrice
                              ? 'plans:checkoutCard.billingDescriptionWithStar'
                              : 'plans:checkoutCard.billingDescriptionWithoutRegularPrice'
                          }
                          values={{
                            price,
                            regularPrice,
                            currency,
                            periodicity: t(`plans:checkoutCard.periodicity.${subscriptionPeriod}`),
                          }}
                          components={[<CrossedSpan color="darkBlue_40" key={0} />]}
                        />
                      }
                      price={pricePerMonth}
                      currency={currency}
                      pricePeriod={t('plans:checkoutCard.perMonthShort')}
                      highlightLabel={
                        setSelectedCard
                          ? showRedNotice
                            ? moneyBackGuaranteeText
                            : t('plans:checkoutCard.biggestSaving')
                          : null
                      }
                      stickerLabel={
                        discountPercentage
                          ? t('plans:checkoutCard.saveDiscount', {
                              discountPercentage,
                            })
                          : null
                      }
                      couponText={
                        coupon && (
                          <Trans
                            i18nKey="plans:checkoutCard.couponCode"
                            values={{
                              couponCode: coupon,
                            }}
                            components={[
                              <Text.Body3
                                as="span"
                                key={0}
                                color="darkBlue_80"
                                data-testid={`plan-card-${subscriptionPeriod}-coupon`}
                              />,
                            ]}
                          />
                        )
                      }
                      highlightLabelDanger={
                        showRedNotice &&
                        selectedCard.id === id &&
                        !isHighlighted && (
                          <Trans
                            i18nKey="plans:checkoutCard.redNotice"
                            values={{
                              planTitle: t(
                                `plans:checkoutCard.periodTitle.${
                                  plans.find((plan) => plan.isHighlighted).subscriptionPeriod
                                }`
                              ),
                            }}
                            components={[<Text.Body3Strong as="span" color="inherit" key={0} />]}
                          />
                        )
                      }
                      moneyBackGuaranteeText={moneyBackGuaranteeText}
                    />
                  </Grid.Col>
                );
              })
            )}
          </PlansWrapper>
        </Grid.Container>
      </Box>
    </ShapeWrapper>

    {/*Coupon*/}
    <Box position="relative">
      <CouponRedeem
        isLoading={isLoading}
        couponCode={plansParameters.couponCode}
        couponError={couponError}
        clickApplyHandler={(couponToApply, onFinish, onError) => {
          if (couponToApply !== plansParameters.couponCode) {
            getByCoupon(couponToApply, {
              onFinishCallback: onFinish,
              onErrorCallback: onError,
            });
          }
        }}
      />
    </Box>

    {showCheckList && (
      <Show.TabletSmall>
        <Box display="flex" justifyContent="center">
          <Box pb={24} px={16} width="fit-content">
            <CheckMarkBullet title={t('checkout:header.choosePlan.features.unlimitedDevices')} />
            <CheckMarkBullet title={t('checkout:header.choosePlan.features.encryption')} />
            <CheckMarkBullet title={t('checkout:header.choosePlan.features.streaming')} />
          </Box>
        </Box>
      </Show.TabletSmall>
    )}
  </React.Fragment>
);

export default ChoosePlan;
